import { useState } from 'react';
import styled, { css } from 'styled-components';
import { submitFeedback } from '../../../api';
import { FeedbackType, ProductProperties } from '../../../api/types';
import vars from '../../vars';
import { SlideOver } from './SlideOver';

interface Props {
  product: ProductProperties;
  onClose: () => void;
  onSubmitted: () => void;
}

export const FeedbackSlideOver = (props: Props) => {
  const [selected, setSelected] = useState<FeedbackType>();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async () => {
    setSubmitted(true);
    await submitFeedback({ type: selected!, productId: props.product.id });
    props.onSubmitted();
  };

  return (
    <SlideOver
      header={
        <>
          <Heading>Spot something wrong?</Heading>
          <Heading $colored>Tell us more!</Heading>
        </>
      }
      onClose={props.onClose}
    >
      <Content>
        <Options>
          {[
            { value: FeedbackType.WrongProductRating, label: 'Rating is wrong' },
            { value: FeedbackType.WrongProduct, label: 'Product is not the one I expected' },
            { value: FeedbackType.WrongProductImage, label: 'The image is of the wrong product!' },
          ].map(({ value, label }) => (
            <Option key={value} onClick={() => setSelected(value)}>
              <OptionIndicator $selected={value === selected} />
              {label}
            </Option>
          ))}
        </Options>
        <SendButton disabled={!selected || submitted} onClick={onSubmit}>
          Send!
        </SendButton>
      </Content>
    </SlideOver>
  );
};

const Heading = styled.div<{ $colored?: boolean }>`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;

  ${(props) =>
    props.$colored &&
    css`
      color: ${vars.colors.brand};
    `}
`;

const Content = styled.div`
  display: flex;
  flex-flow: column;
`;

const Options = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 10px;
  margin-bottom: 50px;
`;

const Option = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px 10px;
  border: none;
  background: none;
  font-size: 16px;
  line-height: 15px;
  color: inherit;
  cursor: pointer;
`;

const OptionIndicator = styled.div<{ $selected: boolean }>`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  border: 1px solid ${vars.colors.brand};
  transition: background-color 0.15s ease-in-out;

  ${(props) =>
    props.$selected &&
    css`
      background-color: ${vars.colors.brand};
    `}
`;

const SendButton = styled.button.attrs({ type: 'button' })`
  align-self: center;
  width: 130px;
  height: 40px;
  padding: 0;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  color: white;
  background-color: ${vars.colors.brand};
  border: 1px solid ${vars.colors.brand};
  cursor: pointer;
  transition: transform 0.15s ease-in-out, color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  &:disabled {
    color: ${vars.colors.brand};
    background-color: transparent;
    cursor: not-allowed;
  }

  &:active:not(:disabled) {
    transform: scale(0.97);
  }
`;
