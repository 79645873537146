const popupWidth = 380;
const vars = {
  colors: {
    brand: '#4F00FF',
    brandDark: '#4100BF',
    charcoal: '#7A7A7A',
    lightGrayBg: 'rgba(63, 64, 84, 0.05)',
    lime: '#defb52',
    letters: {
      A: '#5F8E25',
      B: '#82B841',
      C: '#FFB444',
      D: '#F39609',
      E: '#F37214',
      F: '#E82E2E',
      G: '#B90000',
    },
  },
  breakpoint: {
    default: '@media (max-width: 1440px)',
    mobile: '@media (max-width: 991px)',
    aboveMobile: '@media (min-width: 992px)',
    phone: '@media (max-width: 767px)',
    abovePhone: '@media (min-width: 768px)',
    noBanners: '@media (max-width: 1200px)',
  },
  popupPadding: 24,
  borderRadius: 12,
  popupWidth,
  banners: {
    width: popupWidth,
    compactWidth: popupWidth - 100,
  },
  defaultShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export default vars;
