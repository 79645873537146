export enum ScoreGrade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export enum ScoringResult {
  Grade = 'GRADE',
  NoScore = 'NO_SCORE',
  UnknownScore = 'UNKNOWN_SCORE',
}

export enum DataAvailabilityBucket {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
}

export const scoreGrades = [ScoreGrade.A, ScoreGrade.B, ScoreGrade.C, ScoreGrade.D, ScoreGrade.E, ScoreGrade.F, ScoreGrade.G];

export interface Feature {
  name: string;
  value: string;
}

export interface KeyInfoBadge {
  primary: boolean;
  svgIconUrl: string;
  name: string;
  shortDescription: string;
}

export interface Impact {
  title: string;
  shortDescription: string;
  svgIconUrl: string;
  grade: ScoreGrade;
  expanded?: boolean; // internal usage
}

export interface LearnMore {
  text: string;
  textId: string;
  url: string;
  urlId: string;
}

export interface Social {
  twitter?: TwitterProperties;
}

export interface TwitterProperties {
  text: string;
  url?: string;
  hashtags?: string[];
  via?: string;
}

export interface ContentSnippet {
  contentId: string;
  title: string;
  text: string;
  learnMore?: LearnMore;
  social?: Social;
}

export interface ProductProperties {
  id: string;
  name: string;
  brand: string;
  imageUrl: string;
  pack: string;
  title: string;
  scoringResult: ScoringResult;
  grade?: ScoreGrade;
  dataAvailability?: {
    bucket: DataAvailabilityBucket;
    description: string;
  };
  keyInfoBadges: KeyInfoBadge[];
  impacts: Impact[];
  contentSnippet: ContentSnippet[];
  gradePenalised: boolean;
}

export interface FilterCounter {
  type: FilterType;
  values: {
    value: string;
    count: number;
  }[];
}

export enum FeedbackType {
  WrongProduct = 'WRONG_PRODUCT',
  WrongProductRating = 'WRONG_PRODUCT_RATING',
  WrongProductImage = 'WRONG_PRODUCT_IMAGE',
  CantFindProduct = 'CANT_FIND_PRODUCT',
}

export enum ClickType {
  Product = 'PRODUCT',
  LearnMoreLink = 'LEARN_MORE_LINK',
  Twitter = 'TWITTER',
  Instagram = 'INSTAGRAM',
  HowWeGetThat = 'HOW_WE_GET_THAT',
  MoreOnImpacts = 'MORE_ON_IMPACTS',
  Interested = 'INTERESTED',
}

export interface FilterOption {
  type: FilterType;
  value: string;
}

export enum FilterType {
  Grades = 'GRADES',
  Categories = 'CATEGORIES',
  Stores = 'STORES',
  KeyInfoBadge = 'KEYINFO_BADGES',
  FeatureTag = 'FEATURE_TAG',
}

export interface Sorting {
  sortBy: SortBy;
  order: SortOrder;
}

export enum SortBy {
  Relevance = 'RELEVANCE',
  Grade = 'GRADE',
}

export enum SortOrder {
  Ascending = 'ASC',
  Descending = 'DESC',
}
