import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const usePersistentState = <T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] => {
  const parse = (value: string): any => {
    switch (typeof defaultValue) {
      case 'boolean':
        return value === 'true';
      case 'number':
        return parseFloat(value);
      case 'string':
        return value;
      case 'object':
        return JSON.parse(value);
    }
  };

  const [value, setValue] = useState<T>(() => (localStorage[key] ? parse(localStorage[key]) : defaultValue));

  useEffect(() => {
    if (typeof defaultValue === 'object') {
      localStorage[key] = JSON.stringify(value);
    } else {
      localStorage[key] = value;
    }
  }, [key, value, defaultValue]);

  return [value, setValue];
};
