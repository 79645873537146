import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { getContentSnippets, registerClick } from '../../api';
import { ClickType, ContentSnippet, ProductProperties } from '../../api/types';
import vars from '../vars';

interface Props {
  product: ProductProperties;
  data: ContentSnippet;
}

export const ContentSnippetBox = (props: Props) => {
  const [data, setData] = useState(props.data);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const onTweetClick = () => {
    registerClick(props.product.id, ClickType.Twitter);

    window.open(
      `https://twitter.com/intent/tweet?${[
        ['text', data.social!.twitter!.text],
        ['url', data.social!.twitter!.url],
        ['hashtags', (data.social!.twitter!.hashtags || []).join(',')],
        ['via', data.social!.twitter!.via],
      ]
        .filter(([, value]) => value)
        .map(([key, value]) => `${key}=${encodeURIComponent(value!)}`)
        .join('&')}`,
    );
  };

  const onRefreshClick = async () => {
    setRefreshing(true);

    try {
      setData((await getContentSnippets(1, props.product.id)).data.content[0]);
    } finally {
      setTimeout(() => {
        setRefreshing(false);
      }, 1_000);
    }
  };

  const onLearnMoreClick = () => {
    registerClick(props.product.id, ClickType.LearnMoreLink, [
      {
        contentId: data.contentId,
        textId: data.learnMore!.textId,
        urlId: data.learnMore!.urlId,
      },
    ]);
  };

  return (
    <Root>
      <Header>
        <Title>{data.title}</Title>
        <Buttons>
          {data.social?.twitter && (
            <TweetButton onClick={onTweetClick}>
              <svg width='13' height='13' viewBox='0 0 512 512' fill='currentColor'>
                <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z' />
              </svg>
            </TweetButton>
          )}
          <RefreshButton disabled={refreshing} onClick={onRefreshClick}>
            <svg width='20' height='20' viewBox='0 0 17 16' fill='currentColor'>
              <path d='M1.89638 12.2064V12.9972C1.89638 13.3669 1.59596 13.6667 1.21955 13.6667C0.845751 13.6667 0.542725 13.366 0.542725 12.9972L0.542725 10.3362C0.542725 10.3357 0.542725 10.3352 0.542726 10.3348C0.542725 10.3343 0.542725 10.3338 0.542725 10.3333C0.542725 10.1497 0.618641 9.98343 0.741203 9.86286C0.863008 9.74122 1.03183 9.66667 1.21955 9.66667C1.22003 9.66667 1.22052 9.66667 1.221 9.66667C1.22148 9.66667 1.22196 9.66667 1.22244 9.66667H3.92398C4.29937 9.66667 4.60369 9.96258 4.60369 10.3333C4.60369 10.7015 4.29846 11 3.92398 11H2.73111C3.85959 12.9887 6.0181 14.3333 8.49545 14.3333C12.14 14.3333 15.0945 11.4232 15.0945 7.83333C15.0945 7.77761 15.0938 7.72205 15.0924 7.66667H16.4464C16.4476 7.72208 16.4482 7.77764 16.4482 7.83333C16.4482 12.1596 12.8876 15.6667 8.49545 15.6667C5.74743 15.6667 3.32495 14.2938 1.89638 12.2064ZM15.0945 3.46028V2.66951C15.0945 2.29975 15.3949 2 15.7714 2C16.1452 2 16.4482 2.30065 16.4482 2.66951V5.33049C16.4482 5.33096 16.4482 5.33144 16.4482 5.33191C16.4482 5.33239 16.4482 5.33286 16.4482 5.33333C16.4482 5.51696 16.3723 5.68324 16.2497 5.80381C16.1279 5.92545 15.9591 6 15.7714 6C15.7709 6 15.7704 6 15.7699 6C15.7694 6 15.7689 6 15.7685 6H13.0669C12.6915 6 12.3872 5.70409 12.3872 5.33333C12.3872 4.96514 12.6924 4.66667 13.0669 4.66667H14.2598C13.1313 2.67795 10.9728 1.33333 8.49545 1.33333C4.90746 1.33333 1.98827 4.15384 1.89851 7.66667H0.544489C0.634535 3.41739 4.15982 0 8.49545 0C11.2435 0 13.666 1.37287 15.0945 3.46028Z' />
            </svg>
          </RefreshButton>
        </Buttons>
      </Header>
      <Body>
        <Content>{data.text}</Content>
        {data.learnMore && (
          <LearnMore href={data.learnMore.url} target='_blank' rel='noreferrer' onClick={onLearnMoreClick}>
            {data.learnMore.text}
          </LearnMore>
        )}
      </Body>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${vars.colors.lightGrayBg};
  border-radius: ${vars.borderRadius}px;
  box-shadow: ${vars.defaultShadow};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${vars.colors.brand};
  padding: 12px 16px;
  border-top-left-radius: ${vars.borderRadius}px;
  border-top-right-radius: ${vars.borderRadius}px;
`;

const Title = styled.div`
  color: white;
  font-weight: 600;
  font-size: 15px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 14px;
`;

const ButtonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  padding: 0;
  color: white;
  background-color: transparent;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

const TweetButton = styled.button.attrs({ type: 'button' })`
  ${ButtonCss};
  color: ${vars.colors.brand};
  background-color: white;
`;

const RefreshButton = styled.button.attrs({ type: 'button' })`
  ${ButtonCss}
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px;
`;

const Content = styled.div`
  color: #3f3f3f;
  font-size: 14px;
  line-height: 20px;
`;

const LearnMore = styled.a`
  margin-top: 4px;
  align-self: flex-end;
  color: ${vars.colors.brand};
  font-weight: 600;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
