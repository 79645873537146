import { forwardRef, PropsWithChildren, ReactNode, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import styled, { css } from 'styled-components';
import vars from '../vars';

type Props = PropsWithChildren<{
  title: string;
  slideOvers?: ReactNode;
  headerButton?: ReactNode;
  noScrollFog?: boolean;
  onClose: () => void;
}>;

export interface PopupApi {
  refreshScrollFog: () => void;
  ensureVisibleWithScroll: (element: HTMLElement) => void;
}

export const Popup = forwardRef<PopupApi, Props>((props, ref) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const topScrollFogRef = useRef<HTMLDivElement>(null);
  const bottomScrollFogRef = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [, forceRender] = useReducer((value) => value + 1, 0);
  const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const mobile = 'ontouchstart' in window;
  const enableScrollFog = !props.noScrollFog && !(safari || mobile);

  useEffect(() => {
    if (enableScrollFog) {
      const observer = new ResizeObserver(forceRender);

      observer.observe(scrollableRef.current!);

      return () => {
        observer.disconnect();
      };
    }
  }, [enableScrollFog]);

  useEffect(() => {
    if (!mobile) {
      window.addEventListener('resize', forceRender);

      return () => {
        window.removeEventListener('resize', forceRender);
      };
    }
  }, [mobile]);

  const onScroll = () => {
    if (enableScrollFog) {
      forceRender();
    }
  };

  const refreshScrollFog = () => {
    if (enableScrollFog) {
      forceRender();
    }
  };

  const ensureVisibleWithScroll = (element: HTMLElement) => {
    if (element.getBoundingClientRect().bottom > scrollableRef.current!.clientHeight) {
      scrollableRef.current!.scrollTo(
        0,
        scrollableRef.current!.scrollTop -
          headerRef.current!.getBoundingClientRect().height +
          (enableScrollFog ? bottomScrollFogRef.current!.getBoundingClientRect().height / 2 : 0) +
          element.getBoundingClientRect().bottom -
          scrollableRef.current!.clientHeight,
      );
    }
  };

  useImperativeHandle(ref, () => ({
    refreshScrollFog,
    ensureVisibleWithScroll,
  }));

  return (
    <Root $rightOffset={Math.max(window.innerWidth - 1800, 0) / 2} $mobile={mobile}>
      {props.slideOvers}
      <Header ref={headerRef}>
        <Brand>
          <svg width={headerContentHeight} height={headerContentHeight} viewBox='0 0 24 24'>
            <circle cx='12' cy='12' r='12' fill={vars.colors.brand} />
            <path
              d='M15.5 15C16.8807 15 18 13.8807 18 12.5C18 11.1193 16.8807 10 15.5 10C14.1193 10 13 11.1193 13 12.5C13 13.8807 14.1193 15 15.5 15Z'
              fill='white'
            />
            <path
              d='M7.75 14.5C8.7165 14.5 9.5 13.7165 9.5 12.75C9.5 11.7835 8.7165 11 7.75 11C6.7835 11 6 11.7835 6 12.75C6 13.7165 6.7835 14.5 7.75 14.5Z'
              fill='white'
            />
          </svg>
          <Title>{props.title}</Title>
        </Brand>

        <HeaderRight>
          {props.headerButton}
          <CloseButton onClick={props.onClose}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill={vars.colors.charcoal}>
              <path d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z' />
            </svg>
          </CloseButton>
        </HeaderRight>
      </Header>
      <Body>
        {enableScrollFog && (
          <>
            <TopScrollFog ref={topScrollFogRef} $scrollableElement={scrollableRef.current} $element={topScrollFogRef.current} />
            <BottomScrollFog ref={bottomScrollFogRef} $scrollableElement={scrollableRef.current} $element={bottomScrollFogRef.current} />
          </>
        )}
        <Scrollable ref={scrollableRef} onScroll={onScroll}>
          {props.children}
        </Scrollable>
      </Body>
    </Root>
  );
});

const headerContentHeight = 24;
const sideOffset = 20;

const Scrollable = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: ${vars.popupPadding}px;
  padding-top: 8px;

  overflow-y: scroll;
`;

const Root = styled.div<{ $rightOffset: number; $mobile: boolean }>`
  --side-offset: 0px;
  position: fixed;
  z-index: 2;
  background-color: white;
  width: 100%;
  overflow: hidden;
  top: 0;

  ${Scrollable} {
    max-height: calc(100vh - var(--side-offset) * 2 - ${headerContentHeight + vars.popupPadding * 2}px);
    height: 100vh;
  }

  ${(props) =>
    !props.$mobile &&
    css`
      ${Scrollable} {
        overscroll-behavior: contain;
      }
    `}

  @media (min-width: 640px) {
    --side-offset: ${sideOffset}px;
    width: ${vars.popupWidth}px;
    top: var(--side-offset);
    right: calc(${(props) => props.$rightOffset}px + var(--side-offset));
    border-radius: ${vars.borderRadius}px;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 42px 33px rgba(0, 0, 0, 0.05), 0px 22px 18px rgba(0, 0, 0, 0.04),
      0px 13px 10px rgba(0, 0, 0, 0.03), 0px 7px 5px rgba(0, 0, 0, 0.03), 0px 3px 2px rgba(0, 0, 0, 0.02);

    ${Scrollable} {
      height: auto;
    }

    ${vars.breakpoint.default} {
      --side-offset: 0px;
      border-radius: 0;

      ${Scrollable} {
        height: 100vh;
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${vars.popupPadding}px;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: ${vars.popupPadding}px;
`;

const Brand = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  margin-left: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const CloseButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

const Body = styled.div`
  position: relative;
`;

const ScrollFogCss = css`
  pointer-events: none;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  border-bottom-left-radius: ${vars.borderRadius}px;
  border-bottom-right-radius: ${vars.borderRadius}px;
`;

const TopScrollFog = styled.div<{ $scrollableElement: HTMLElement | null; $element: HTMLElement | null }>`
  ${ScrollFogCss}
  top: 0;
  height: 60px;
  background-image: ${(props) => {
    if (!props.$scrollableElement || !props.$element) {
      return 'none';
    }

    const fadingMargin = props.$element.getBoundingClientRect().height / 2;
    const maxScrollTop = props.$scrollableElement.scrollHeight - props.$scrollableElement.clientHeight;
    const intensity = maxScrollTop > 0 ? Math.min(fadingMargin, props.$scrollableElement.scrollTop) / fadingMargin : 0;
    return `linear-gradient(180deg, rgba(255, 255, 255, 1) ${intensity * 15}%, rgba(255, 255, 255, 0) ${intensity * 100}%)`;
  }};
`;

const BottomScrollFog = styled.div<{ $scrollableElement: HTMLElement | null; $element: HTMLElement | null }>`
  ${ScrollFogCss}
  bottom: 0;
  height: 120px;
  background-image: ${(props) => {
    if (!props.$scrollableElement || !props.$element) {
      return 'none';
    }

    const fadingMargin = props.$element.getBoundingClientRect().height / 2;
    const maxScrollTop = props.$scrollableElement.scrollHeight - props.$scrollableElement.clientHeight;
    const intensity = maxScrollTop > 0 ? Math.min(fadingMargin, maxScrollTop - props.$scrollableElement.scrollTop) / fadingMargin : 0;
    return `linear-gradient(0deg, rgba(255, 255, 255, 1) ${intensity * 15}%, rgba(255, 255, 255, 0) ${intensity * 100}%)`;
  }};
`;
