import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Altform;
    src:  url('https://static.euw1.sustained.app/client/v1/fonts/Altform-Regular.woff2') format('woff2'),
          url('https://static.euw1.sustained.app/client/v1/fonts/Altform-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: Altform;
    src:  url('https://static.euw1.sustained.app/client/v1/fonts/Altform-SemiBold.woff2') format('woff2'),
          url('https://static.euw1.sustained.app/client/v1/fonts/Altform-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  html {
    font-family:
      Altform,
      -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: black;
    background-color: white;
    overflow-x: hidden;
    text-size-adjust: 100%;
  }
`;
