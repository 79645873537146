import { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from './GlobalStyle';
import './reset.css';
import { RootView } from './RootView';

export const initApp = () =>
  render(
    <StrictMode>
      <BrowserRouter>
        <GlobalStyle />
        <RootView />
      </BrowserRouter>
    </StrictMode>,
    document.getElementById('root'),
  );
