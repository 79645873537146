import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import vars from '../../vars';

type Props = PropsWithChildren<{
  onClose: () => void;
  header?: ReactNode;
}>;

export const SlideOver = (props: Props) => {
  const mobile = 'ontouchstart' in window;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setVisible(true));
  }, []);

  return (
    <Root $visible={visible}>
      <Backdrop onClick={props.onClose} />
      <Content>
        <Header>
          <div>{props.header}</div>
          <CloseButton onClick={props.onClose}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill={vars.colors.charcoal}>
              <path d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z' />
            </svg>
          </CloseButton>
        </Header>
        <Body $mobile={mobile}>{props.children}</Body>
      </Content>
    </Root>
  );
};

const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-out;
  opacity: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: calc(100% - ${vars.popupPadding * 3}px);
  border-radius: ${vars.borderRadius}px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: white;
  transition: transform 0.3s ease-out;
  transform: translateY(300px);
`;

const Root = styled.div<{ $visible: boolean }>`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;

  ${(props) =>
    props.$visible &&
    css`
      ${Backdrop} {
        opacity: 1;
      }

      ${Content} {
        transform: translateY(0);
      }
    `}
`;

const CloseButton = styled.button.attrs({ type: 'button' })`
  align-self: flex-start;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${vars.popupPadding}px;
  font-size: 15px;
`;

const Body = styled.div<{ $mobile: boolean }>`
  flex: 1;
  display: flex;
  flex-flow: column;
  padding: ${vars.popupPadding}px;
  padding-top: 0;
  overflow: scroll;

  ${(props) =>
    !props.$mobile &&
    css`
      overscroll-behavior: contain;
    `}
`;
