import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { submitFeedback } from '../../api';
import { FeedbackType } from '../../api/types';
import vars from '../vars';
import { ContentWidthCss } from './sharedStyles';

export const SuggestWidget = () => {
  const mobile = 'ontouchstart' in window;
  const inputRef = useRef<HTMLInputElement>(null);
  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setSubmitted(false);
      setInput('');

      if (!mobile) {
        inputRef.current!.focus();
      }
    }
  }, [open, mobile]);

  const onSubmit = async () => {
    setSubmitted(true);
    await submitFeedback({ type: FeedbackType.CantFindProduct, userMessage: input });

    setTimeout(() => {
      setOpen(false);
    }, 1_000);
  };

  return (
    <Root $open={open}>
      <Backdrop onClick={() => setOpen(false)} />
      <Panel>
        <ToggleButton onClick={() => setOpen(!open)}>
          <div>Not finding what you're looking for?</div>
          <ToggleIcon width='13' height='8' viewBox='0 0 13 8' fill='currentColor'>
            <path d='M12.7557 7.71925C12.8331 7.63496 12.8946 7.53487 12.9365 7.4247C12.9784 7.31453 13 7.19644 13 7.07717C13 6.95791 12.9784 6.83981 12.9365 6.72964C12.8946 6.61947 12.8331 6.51938 12.7557 6.4351L7.09664 0.266166C7.01926 0.181788 6.92738 0.114849 6.82624 0.0691781C6.7251 0.0235074 6.61669 -2.79023e-07 6.5072 -2.83809e-07C6.39772 -2.88595e-07 6.2893 0.0235073 6.18816 0.0691781C6.08702 0.114849 5.99514 0.181788 5.91776 0.266166L0.258749 6.4351C0.178199 6.51861 0.113812 6.61884 0.0693833 6.72988C0.0249545 6.84093 0.00138348 6.96054 5.88183e-05 7.08166C-0.00126584 7.20279 0.0196835 7.32297 0.0616719 7.43514C0.103659 7.54731 0.165837 7.64918 0.244538 7.73476C0.323239 7.82034 0.41687 7.88789 0.519914 7.93343C0.622959 7.97897 0.73333 8.00157 0.844522 7.99992C0.955714 7.99826 1.06548 7.97237 1.16734 7.92378C1.2692 7.87519 1.3611 7.80487 1.43762 7.71698L6.5072 2.19013L11.5768 7.71698C11.654 7.80151 11.7458 7.86862 11.8469 7.91449C11.9479 7.96035 12.0563 7.98407 12.1658 7.98428C12.2753 7.98449 12.3837 7.96119 12.4849 7.91572C12.5861 7.87024 12.6781 7.80348 12.7557 7.71925Z' />
          </ToggleIcon>
        </ToggleButton>
        <p>If you can’t find a product, it means we don’t have it in our database.</p>
        <p>If you really want us to try and get it for you, let us know by typing here!</p>
        <Input
          ref={inputRef}
          placeholder="Type product's name…"
          disabled={submitted}
          value={input}
          onChange={(event) => setInput(event.target.value)}
        />
        <SendButton $submitted={submitted} disabled={!input || submitted} onClick={onSubmit}>
          {submitted ? 'Thank you!' : 'Send'}
        </SendButton>
      </Panel>
    </Root>
  );
};

const ButtonCss = css`
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  user-select: none;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-out;
`;

const Panel = styled.div`
  ${ContentWidthCss};
  pointer-events: all;
  display: flex;
  flex-flow: column;
  gap: 20px;
  padding: 22px;
  padding-top: 0;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  color: white;
  background-color: ${vars.colors.brand};
  border-radius: 5px 5px 0px 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  transition: padding 0.3s ease-out, transform 0.3s ease-out;
`;

const ToggleButton = styled.button.attrs({ type: 'button' })`
  ${ButtonCss};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  gap: 20px;
  color: white;
`;

const ToggleIcon = styled.svg``;

const Root = styled.div<{ $open: boolean }>`
  z-index: 1;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: ${(props) => (props.$open ? 'all' : 'none')};

  ${Backdrop} {
    opacity: ${(props) => (props.$open ? 1 : 0)};
  }

  ${ToggleIcon} {
    transform: scaleY(${(props) => (props.$open ? -1 : 1)});
  }

  ${Panel} {
    --hover-offset: 0px;

    @media (hover: hover) {
      ${(props) =>
        !props.$open &&
        css`
          &:hover {
            --hover-offset: -5px;
          }
        `}
    }

    transform: translateY(calc(100% - 38px + var(--hover-offset)));

    ${(props) =>
      props.$open &&
      css`
        transform: translateY(0);
      `}
  }
`;

const Input = styled.input`
  padding: 8px 0;
  width: 100%;
  border: none;
  background: none;
  outline: none;
  border-radius: 0;
  font-size: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  color: white;

  &:disabled {
    opacity: 1;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const SendButton = styled.button.attrs({ type: 'button' })<{ $submitted: boolean }>`
  ${ButtonCss};
  align-self: center;
  width: 130px;
  height: 40px;
  padding: 0;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  color: ${vars.colors.brand};
  background-color: white;
  border: 1px solid white;
  cursor: pointer;
  transition: transform 0.15s ease-in-out, color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  &:disabled {
    color: white;
    background-color: transparent;
    cursor: ${(props) => (props.$submitted ? 'default' : 'not-allowed')};
  }

  &:active:not(:disabled) {
    transform: scale(0.97);
  }
`;
