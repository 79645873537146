import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { getSearchFilters, sendActivationMetric } from '../../api';
import { FilterOption, FilterType } from '../../api/types';
import { trackInstall } from '../../googleAnalytics';
import vars from '../vars';
import { ContentWidthCss } from './sharedStyles';
import { SideBanners } from './SideBanners';
import { Search } from './Search';

export const RootView = () => {
  const [menu, setMenu] = useState(false);
  const [activated, setActivated] = useState(!!localStorage.getItem('userId'));
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>();

  useEffect(() => {
    (async () => {
      try {
        setFilterOptions(
          (
            await getSearchFilters([
              FilterType.Grades,
              FilterType.Categories,
              FilterType.Stores,
              FilterType.FeatureTag,
              FilterType.KeyInfoBadge,
            ])
          ).data.searchFilters,
        );
      } catch (error) {
        setFilterOptions([]);
      }
    })();
  }, []);

  useEffect(() => {
    if (!activated) {
      (async () => {
        localStorage.setItem('userId', (await sendActivationMetric(`to-be-removed.${Math.random()}`)).data!.activationMetric.userId);
        setActivated(true);
      })();
    }
  }, [activated]);

  const renderMenuItems = () => (
    <>
      <MenuItem href='https://sustained.com'>Home</MenuItem>
      <MenuItem href='https://search.sustained.com' data-selected>
        Find Products
      </MenuItem>
      <MenuItem href='https://sustained.com/the-challenge'>The Challenge</MenuItem>
      <MenuItem href='https://sustained.com/our-approach'>Our Approach</MenuItem>
      <MenuItem href='https://sustained.com/about'>About</MenuItem>
      <MenuItem href='https://sustained.com/blog'>Blog</MenuItem>
      {menu && <MenuItem href='https://sustained.com/privacy-policy'>Privacy Policy</MenuItem>}
      {menu && <MenuItem href='https://sustained.com/terms-conditions'>Terms &amp; Conditions</MenuItem>}
    </>
  );

  if (!filterOptions || !activated) {
    return <></>;
  }

  return (
    <Root $menu={menu}>
      <Navigation.Root>
        <Navigation.Content>
          <Navigation.LeftSide>
            <a href='https://sustained.com'>
              <Navigation.Logo src='/logo-text.svg' />
            </a>
          </Navigation.LeftSide>
          <Navigation.RightSide>
            {renderMenuItems()}
            <Navigation.InstallButton href='https://sustained.com/install' target='_blank' rel='noreferrer' onClick={trackInstall}>
              Install now
            </Navigation.InstallButton>
            <Navigation.MenuButton onClick={() => setMenu(!menu)}>
              <Navigation.MenuButtonLine />
              <Navigation.MenuButtonLine />
              <Navigation.MenuButtonLine />
            </Navigation.MenuButton>
          </Navigation.RightSide>
        </Navigation.Content>
      </Navigation.Root>
      <MobileMenu>{renderMenuItems()}</MobileMenu>
      <SearchWrapper><Search filterOptions={filterOptions} /></SearchWrapper>
      <SideBanners topNavigationHeight={navigationDefaultHeight} />
      <DesktopLegalFooter>
        <a href='https://sustained.com/privacy-policy' target='_blank' rel='noreferrer'>
          Privacy Policy
        </a>
        <a href='https://sustained.com/terms-conditions' target='_blank' rel='noreferrer'>
          Terms &amp; Conditions
        </a>
      </DesktopLegalFooter>
    </Root>
  );
};

const MenuItem = styled.a`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  padding: 0 15px;
  color: inherit;
`;

const navigationDefaultHeight = 90;

const Navigation = {
  Root: styled.div`
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    height: ${navigationDefaultHeight}px;
    background-color: white;

    ${vars.breakpoint.phone} {
      padding-top: 3px;
    }

    ${vars.breakpoint.phone} {
      height: 60px;
    }
  `,
  Content: styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    gap: 20px;

    ${vars.breakpoint.default} {
      width: 90%;
    }
  `,
  Logo: styled.img.attrs({ alt: 'Logo' })`
    width: 260px;

    ${vars.breakpoint.phone} {
      margin-left: 10px;
      width: 200px;
    }
  `,
  LeftSide: styled.div`
    display: flex;
    align-items: center;
    height: 100%;
  `,
  RightSide: styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 100%;

    ${MenuItem} {
      transition: color 0.15s ease-in-out;

      @media (hover: hover) {
        &:hover {
          color: ${vars.colors.brand};
        }
      }

      &[data-selected],
      &:active {
        color: ${vars.colors.brand};
      }

      ${vars.breakpoint.mobile} {
        display: none;
      }
    }
  `,
  InstallButton: styled.a`
    margin-left: 15px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 16px;
    padding: 0 27px;
    height: 50px;
    font-weight: 600;
    color: white;
    border-radius: 30px;
    background-color: ${vars.colors.brand};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

    ${vars.breakpoint.mobile} {
      display: none;
    }
  `,
  MenuButton: styled.button.attrs({ type: 'button' })`
    box-sizing: content-box;
    padding: 10px;
    width: 20px;
    height: 15px;
    border: none;
    background: none;
    cursor: pointer;
    user-select: none;
    display: none;

    ${vars.breakpoint.mobile} {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }
  `,
  MenuButtonLine: styled.div`
    background-color: black;
    width: 100%;
    height: 3px;
    border-radius: 2px;
  `,
};

const MobileMenu = styled.div`
  flex-flow: column;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  color: white;
  background-color: ${vars.colors.brand};

  ${MenuItem} {
    flex: 1;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
  }
`;

const DesktopTabs = {
  Root: styled.div`
    ${ContentWidthCss};
    justify-content: center;
    flex-shrink: 1;
    font-weight: 600;
    padding: 0 40px;
  `,
  Item: styled.button.attrs({ type: 'button' })<{ $selected: boolean }>`
    flex: 1;
    padding: 10px 0;
    border: none;
    background: none;
    border-bottom: 2px solid #c4c4c4;
    color: #c4c4c4;
    cursor: pointer;

    ${(props) =>
      props.$selected &&
      css`
        color: ${vars.colors.brand};
        border-color: ${vars.colors.brand};
      `}
  `,
};

const MobileTabs = {
  Root: styled.div`
    z-index: 1;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    border-top: 1px solid rgba(122, 122, 122, 0.1);
  `,
  Item: styled.button.attrs({ type: 'button' })<{ $selected: boolean }>`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    flex: 1;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 10px;
    color: ${vars.colors.charcoal};

    ${(props) =>
      props.$selected &&
      css`
        color: ${vars.colors.brand};
      `}
  `,
  Icon: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
  `,
};

const SearchWrapper = styled.div`
  width: 100%;
`;

const DesktopLegalFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: ${vars.banners.width}px;
  position: fixed;
  bottom: 10px;
  right: 0;

  ${vars.breakpoint.default} {
    width: ${vars.banners.compactWidth}px;
  }

  ${vars.breakpoint.mobile} {
    display: none;
  }

  a {
    font-size: 12px;
    text-decoration: none;
    color: white;
  }

  ${vars.breakpoint.noBanners} {
    width: auto;
    right: 20px;

    a {
      font-size: 10px;
      color: ${vars.colors.charcoal};
    }
  }
`;

const Root = styled.div<{ $menu: boolean }>`
  --mobile-menu-padding: 0px;
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: calc(40px + var(--mobile-menu-padding));

  ${DesktopTabs.Root}, ${SearchWrapper} {
    display: flex;
  }

  ${MobileTabs.Root}, ${MobileMenu} {
    display: none;
  }

  ${vars.breakpoint.mobile} {
    ${DesktopTabs.Root} {
      display: none;
    }

    ${MobileTabs.Root} {
      display: flex;
    }

    ${(props) =>
      props.$menu &&
      css`
        ${SearchWrapper} {
          display: none;
        }

        ${MobileMenu} {
          display: flex;
        }
      `}
  }
`;
