import { ApolloClient, createHttpLink, from, gql, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import TimeoutLink from 'apollo-link-timeout';
import { v4 as uuidv4 } from 'uuid';
import {
  ClickType,
  ContentSnippet,
  Feature,
  FeedbackType,
  FilterCounter,
  FilterOption,
  FilterType,
  ProductProperties,
  ScoringResult,
  Sorting,
} from './types';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    new TimeoutLink(10_000),
    new RetryLink({
      delay: {
        initial: 300,
        jitter: true,
      },
      attempts: {
        max: 4,
      },
    }),
    setContext(async (_, { headers }) => ({
      headers: {
        ...headers,
        'X-Sustained-Internal-Usage': document.documentElement.hasAttribute('sustained-internal'),
        ...(localStorage.getItem('userId')
          ? {
              'X-Sustained-Endpoint-Sid': localStorage.getItem('userId'),
            }
          : {}),
        'X-Sustained-Request-Sid': `req.${uuidv4().replace(/-/g, '')}`,
        'X-Sustained-Client-Info': 'web-app',
      },
    })),
    createHttpLink({
      uri: `https://graphql.${process.env.REACT_APP_ENV === 'dev' ? 'dev-' : ''}euw1.sustained.app`,
    }),
  ]),
});

export const sendActivationMetric = (instanceId: string) =>
  client.mutate<{ activationMetric: { userId: string } }>({
    mutation: gql`
      mutation ($instanceId: String!) {
        activationMetric(input: { instanceId: $instanceId }) {
          userId
        }
      }
    `,
    variables: {
      instanceId,
    },
  });

export const search = (params: {
  searchString: string;
  filters: FilterOption[];
  sorting: Sorting;
  limit: number;
  pageToken?: string;
  noScoreOnly?: boolean;
}) =>
  client.query<{
    search: {
      productResults: {
        pageToken: string;
        totalResultsCount: number;
        products: ProductProperties[];
        filterCounters: FilterCounter[];
      };
    };
  }>({
    query: gql`
      query ($filter: SearchFilterInput!) {
        search(filter: $filter) {
          productResults {
            pageToken
            totalResultsCount
            products {
              id
              name
              brand
              pack
              grade
              imageUrl
              scoringResult
              keyInfoBadges {
                primary
                svgIconUrl
                name
              }
            }
            filterCounters {
              type
              values {
                value
                count
              }
            }
          }
        }
      }
    `,
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        name: params.searchString,
        filters: params.filters,
        scoringResults: params.noScoreOnly ? [ScoringResult.NoScore] : undefined,
        sorting: params.sorting,
        limit: params.limit,
        fuzziness: true,
        productsPageToken: params.pageToken || '',
      },
    },
  });

export const getSearchFilters = (types: FilterType[]) =>
  client.query<{ searchFilters: FilterOption[] }>({
    query: gql`
      query ($types: [FilterTypeEnum]!) {
        searchFilters(types: $types) {
          type
          value
        }
      }
    `,
    variables: {
      types,
    },
  });

export const getProductForDetails = (request: { correlationId: string; features: Feature[] }) =>
  client.query<{ products: { product: ProductProperties }[] }>({
    query: gql`
      query ($requests: [ProductRequestsInput!]!) {
        products(requests: $requests) {
          product {
            id
            name
            brand
            pack
            grade
            imageUrl
            scoringResult
            dataAvailability {
              bucket
              description
            }
            keyInfoBadges {
              svgIconUrl
              name
              shortDescription
            }
            impacts {
              title
              shortDescription
              grade
              svgIconUrl
            }
            gradePenalised
            contentSnippet {
              contentId
              title
              text
              learnMore {
                text
                textId
                url
                urlId
              }
              social {
                twitter {
                  text
                  url
                  hashtags
                  via
                }
              }
            }
          }
        }
      }
    `,
    fetchPolicy: 'no-cache',
    variables: {
      requests: [request],
    },
  });

export const getContentSnippets = (limit: number, productId: string) =>
  client.query<{ content: ContentSnippet[] }>({
    query: gql`
      query ($input: ContentInput) {
        content(input: $input) {
          contentId
          title
          text
          learnMore {
            text
            textId
            url
            urlId
          }
          social {
            twitter {
              text
              url
              hashtags
              via
            }
          }
        }
      }
    `,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        limit,
        productId,
      },
    },
  });

export const submitFeedback = (params: { type: FeedbackType; productId?: string; userMessage?: string }) =>
  client.mutate({
    mutation: gql`
      mutation ($type: FeedbackTypeEnum!, $productId: String, $userMessage: String) {
        feedback(input: { type: $type, productId: $productId, userMessage: $userMessage }) {
          requestId
        }
      }
    `,
    variables: params,
  });

export const registerClick = (productId: string, type: ClickType, content: { contentId: string; textId: string; urlId: string }[] = []) =>
  client.mutate({
    mutation: gql`
      mutation ($productId: String, $type: ClickTypeEnum!, $content: [ClickContentInput]) {
        clickMetric(input: { productId: $productId, type: $type, content: $content }) {
          requestId
        }
      }
    `,
    variables: {
      productId,
      type,
      content,
    },
  });
