declare global {
  interface Window {
    dataLayer: { event: string }[];
  }
}

export const trackInstall = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'install_click' });
};
