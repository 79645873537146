import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ScoreGrade, scoreGrades, ScoringResult } from '../../api/types';
import vars from '../vars';

interface Props {
  scoringResult: ScoringResult;
  grade?: ScoreGrade;
  noTopLabel?: boolean;
  keyInfoBadgeIcon?: ReactNode;
  sizeMultiplier: number;
}

export const GradeBadge = (props: Props) => (
  <Root $sizeMultiplier={props.sizeMultiplier} $hasKeyInfoBadge={!!props.keyInfoBadgeIcon}>
    {!props.noTopLabel && props.scoringResult === ScoringResult.Grade && (
      <TopLabel $sizeMultiplier={props.sizeMultiplier}>
        <TopLabelText>Estimated</TopLabelText>
      </TopLabel>
    )}
    <Body $sizeMultiplier={props.sizeMultiplier}>
      <Content $sizeMultiplier={props.sizeMultiplier}>
        {props.scoringResult === ScoringResult.Grade ? (
          scoreGrades.map((grade) => (
            <Grade
              $sizeMultiplier={props.sizeMultiplier}
              key={grade}
              $selected={grade === props.grade}
              style={grade === props.grade ? { backgroundColor: vars.colors.letters[grade] } : {}}
            >
              {grade}
            </Grade>
          ))
        ) : (
          <BadScoringResultText>
            {props.scoringResult === ScoringResult.UnknownScore ? 'Not enough data' : 'Working on it'}
          </BadScoringResultText>
        )}
      </Content>
      <KeyInfoBadge>{props.keyInfoBadgeIcon}</KeyInfoBadge>
    </Body>
  </Root>
);

const width = 116;
const bodyHeight = 32;
const topLabelHeight = 16;
const topLabelInset = -6;
const keyInfoBadgeWidth = 30;
const keyInfoBadgeInset = -4;

const Body = styled.div<{ $sizeMultiplier: number }>`
  display: flex;
  width: 100%;
  border-radius: ${bodyHeight}px;
  box-shadow: ${vars.defaultShadow};
  ${(props) => css`
    height: ${bodyHeight * props.$sizeMultiplier}px;
  `}
`;

const KeyInfoBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${keyInfoBadgeInset}px;
  width: ${keyInfoBadgeWidth}px;
  height: 100%;
  color: white;
`;

const Root = styled.div<{ $sizeMultiplier: number; $hasKeyInfoBadge: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${(props) =>
    props.$hasKeyInfoBadge
      ? css`
          width: ${(width + keyInfoBadgeWidth + keyInfoBadgeInset) * props.$sizeMultiplier}px;

          ${Body} {
            background-image: linear-gradient(90deg, transparent 50%, ${vars.colors.brand} 50%);
          }
        `
      : css`
          width: ${width * props.$sizeMultiplier}px;
          ${KeyInfoBadge} {
            display: none;
          }
        `}
`;

const TopLabel = styled.div<{ $sizeMultiplier: number }>`
  z-index: 1;
  border-radius: ${topLabelHeight / 2}px;
  height: ${topLabelHeight}px;
  margin-bottom: ${topLabelInset}px;
  margin-left: 13px;
  padding: 0 10px;
  padding-top: 2px;
  font-weight: 600;
  letter-spacing: 0.5px;
  background-image: linear-gradient(0deg, transparent 30%, #f4f4f4 30%);
  ${(props) => css`
    font-size: ${7 * props.$sizeMultiplier}px;
  `}
`;

const TopLabelText = styled.span`
  color: #404040;
  opacity: 0.5;
`;

const Content = styled.div<{ $sizeMultiplier: number }>`
  border-radius: ${bodyHeight}px;
  height: 100%;
  padding: 0 5px;
  flex: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 0.5;
  color: #404040;
  background-color: #f4f4f4;
  ${(props) => css`
    font-size: ${14 * props.$sizeMultiplier}px;
  `}
`;

const Grade = styled.div<{ $sizeMultiplier: number; $selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  width: 14px;

  ${(props) => css`
    font-size: ${10 * props.$sizeMultiplier}px;
  `}

  ${(props) =>
    props.$selected &&
    css`
      color: white;
      opacity: 1;
      font-size: ${14 * props.$sizeMultiplier}px;
      border-radius: 50%;
      width: 22px;
      height: 22px;
    `}
`;

const BadScoringResultText = styled.span`
  font-size: 12px;
  opacity: 0.5;
`;
