import { KeyboardEvent, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FilterCounter, FilterOption, FilterType, ScoreGrade } from '../../../api/types';
import vars from '../../vars';
import { Popup } from '../Popup';

interface Props {
  options: FilterOption[];
  selected: FilterOption[];
  counters: FilterCounter[];
  onClick: (option: FilterOption) => void;
  onResetType: (type: FilterType) => void;
  onResetAll: () => void;
  onClose: () => void;
}

export const FiltersPopup = (props: Props) => {
  const [expanded, setExpanded] = useState(new Array<FilterType>());
  const [categorySearchInput, setCategorySearchInput] = useState('');
  const categorySearchInputRef = useRef<HTMLInputElement>(null);
  const mobile = 'ontouchstart' in window;

  const onSearchInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (mobile && event.key === 'Enter') {
      categorySearchInputRef.current!.blur();
    }
  };

  const isSelected = (option: FilterOption) => props.selected.some(({ type, value }) => type === option.type && value === option.value);

  const getCounts = (type: FilterType) => props.counters.find((count) => count.type === type)?.values || [];

  const getCount = (option: FilterOption) => getCounts(option.type).find(({ value }) => value === option.value)?.count ? `(${getCounts(option.type).find(({ value }) => value === option.value)?.count})` : '';

  return (
    <Popup
      title='Filters'
      onClose={props.onClose}
      headerButton={<ResetAllButton onClick={props.onResetAll}>Reset All</ResetAllButton>}
      noScrollFog
    >
      <Content>
        <Grades.Root>
          <Grades.Title>Sustained rating</Grades.Title>
          <Grades.Options>
            {props.options
              .filter((option) => option.type === FilterType.Grades)
              .map((option) => (
                <Grades.Option key={option.value} onClick={() => props.onClick(option)}>
                  <Grades.OptionIndicator $grade={option.value as ScoreGrade} $selected={isSelected(option)} />
                  {option.value}
                  <ResultsCount>{getCount(option)}</ResultsCount>
                </Grades.Option>
              ))}
          </Grades.Options>
        </Grades.Root>
        {[
          { type: FilterType.Stores, title: 'Supermarket' },
          { type: FilterType.Categories, title: 'Category' },
          { type: FilterType.FeatureTag, title: 'Properties' },
          { type: FilterType.KeyInfoBadge, title: 'Additional information' },
        ].map((group) => {
          const options = props.options.filter((option) => option.type === group.type);
          const selected = props.selected.filter((option) => option.type === group.type);

          return (
            <Group.Root key={group.type} $expanded={expanded.includes(group.type)}>
              <Group.Header
                onClick={() =>
                  setExpanded((current) =>
                    current.includes(group.type) ? current.filter((type) => type !== group.type) : [...current, group.type],
                  )
                }
              >
                {group.title}
                <Group.HeaderRight>
                  {selected.length > 0 && <Group.Counter>{selected.length}</Group.Counter>}
                  <Group.ExpandIcon width='18' height='10' viewBox='0 0 18 10' fill='currentColor'>
                    <path d='M0.328926 0.35094C0.224652 0.456296 0.14193 0.581409 0.0854902 0.719125C0.0290507 0.85684 1.07271e-07 1.00446 1.05493e-07 1.15354C1.03715e-07 1.30262 0.0290506 1.45023 0.0854902 1.58795C0.14193 1.72566 0.224652 1.85078 0.328926 1.95613L7.94683 9.66729C8.05099 9.77277 8.17468 9.85644 8.31083 9.91353C8.44698 9.97062 8.59292 10 8.7403 10C8.88769 10 9.03363 9.97062 9.16978 9.91353C9.30593 9.85644 9.42962 9.77277 9.53378 9.66729L17.1517 1.95613C17.2601 1.85174 17.3468 1.72645 17.4066 1.58765C17.4664 1.44884 17.4981 1.29933 17.4999 1.14792C17.5017 0.996517 17.4735 0.846283 17.417 0.706076C17.3605 0.565868 17.2768 0.438525 17.1708 0.331554C17.0649 0.224581 16.9388 0.140144 16.8001 0.0832188C16.6614 0.0262949 16.5128 -0.00196533 16.3631 0.000106053C16.2135 0.00217743 16.0657 0.0345366 15.9286 0.095277C15.7915 0.156018 15.6677 0.243913 15.5647 0.353777L8.7403 7.26234L1.91587 0.353777C1.8119 0.248117 1.68835 0.164222 1.5523 0.10689C1.41625 0.0495577 1.27037 0.0199118 1.12298 0.0196486C0.975596 0.0193853 0.829605 0.0485106 0.693356 0.105356C0.557106 0.162201 0.433269 0.245652 0.328926 0.35094Z' />
                  </Group.ExpandIcon>
                </Group.HeaderRight>
              </Group.Header>
              {expanded.includes(group.type) && (
                <Group.Body>
                  {group.type === FilterType.Categories && (
                    <Categories>
                      <SearchInputContainer>
                        <SearchInput
                          ref={categorySearchInputRef}
                          value={categorySearchInput}
                          placeholder='Find a category…'
                          onChange={(event) => setCategorySearchInput(event.target.value)}
                          onKeyDown={onSearchInputKeyDown}
                          autoFocus={!mobile}
                          autoCorrect='off'
                          autoComplete='off'
                          autoCapitalize='off'
                        />
                        <SearchIcon viewBox='0 0 16 16'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M1.6 7.20001C1.6 4.1072 4.1072 1.6 7.20001 1.6C10.2928 1.6 12.8 4.1072 12.8 7.20001C12.8 10.2928 10.2928 12.8 7.20001 12.8C4.1072 12.8 1.6 10.2928 1.6 7.20001ZM7.20001 0C3.22352 0 0 3.22352 0 7.20001C0 11.1765 3.22352 14.4 7.20001 14.4C8.90001 14.4 10.4624 13.8108 11.6942 12.8255L14.6343 15.7657C14.9467 16.0781 15.4533 16.0781 15.7657 15.7657C16.0781 15.4533 16.0781 14.9467 15.7657 14.6343L12.8255 11.6942C13.8108 10.4624 14.4 8.90001 14.4 7.20001C14.4 3.22352 11.1765 0 7.20001 0Z'
                          />
                        </SearchIcon>
                      </SearchInputContainer>
                      {options
                        .filter(({ value }) => !categorySearchInput || value.toLowerCase().includes(categorySearchInput.toLowerCase()))
                        .map((option) => (
                          <Category key={option.value} $selected={isSelected(option)} onClick={() => props.onClick(option)}>
                            {option.value}
                            <ResultsCount>{getCount(option)}</ResultsCount>
                          </Category>
                        ))}
                    </Categories>
                  )}
                  {group.type === FilterType.Stores && (
                    <Options $renderInline={true}>
                      {options
                        .map((option) => (
                          <Option key={option.value} onClick={() => props.onClick(option)}>
                            <OptionIndicator $selected={isSelected(option)} />
                            {option.value}
                            <ResultsCount>{getCount(option)}</ResultsCount>
                          </Option>
                        ))}
                    </Options>
                  )}
                  {group.type === FilterType.KeyInfoBadge && (
                    <Options $renderInline={false}>
                      {options
                        .map((option) => (
                          <Option key={option.value} onClick={() => props.onClick(option)}>
                            <OptionIndicator $selected={isSelected(option)} />
                            {option.value}
                            <ResultsCount>{getCount(option)}</ResultsCount>
                          </Option>
                        ))}
                    </Options>
                  )}
                  {group.type === FilterType.FeatureTag && (
                    <Options $renderInline={false}>
                      {options
                        .map((option) => (
                          <Option key={option.value} onClick={() => props.onClick(option)}>
                            <OptionIndicator $selected={isSelected(option)} />
                            {option.value}
                            <ResultsCount>{getCount(option)}</ResultsCount>
                          </Option>
                        ))}
                    </Options>
                  )}
                  <Group.ResetButtonWrapper>
                    <Group.ResetButton onClick={() => props.onResetType(group.type)}>Reset</Group.ResetButton>
                  </Group.ResetButtonWrapper>
                </Group.Body>
              )}
            </Group.Root>
          );
        })}
      </Content>
    </Popup>
  );
};

const ButtonCss = css`
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  user-select: none;
`;

const Content = styled.div`
  display: flex;
  flex-flow: column;
  gap: 2px;
`;

const ResetAllButton = styled.button.attrs({ type: 'button' })`
  ${ButtonCss};
  display: flex;
  font-weight: 600;
  line-height: 1;
  color: inherit;
`;

const ResultsCount = styled.div`
  font-size: 10px;
`;

const Grades = {
  Root: styled.div`
    display: flex;
    flex-flow: column;
    padding: 0 18px;
    gap: 24px;
    margin-bottom: 36px;
  `,
  Title: styled.div`
    font-size: 18px;
    font-weight: 600;
    color: ${vars.colors.brand};
  `,
  Options: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 14px;
    font-size: 14px;
  `,
  Option: styled.button.attrs({ type: 'button' })`
    ${ButtonCss};
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    color: inherit;

    ${ResultsCount} {
      color: rgb(159, 159, 159);
    }
  `,
  OptionIndicator: styled.div<{ $grade: ScoreGrade; $selected: boolean }>`
    border-radius: 5px;
    margin-right: 6px;
    width: 21px;
    height: 21px;
    border: 1px solid rgb(100, 100, 100);
    transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;

    ${(props) =>
      props.$selected &&
      css`
        border-color: ${vars.colors.letters[props.$grade]};
        background-color: ${vars.colors.letters[props.$grade]};
      `}
  `,
};

const Group = (() => {
  const Counter = styled.div`
    font-size: 14px;
  `;

  const ExpandIcon = styled.svg``;

  return {
    Counter,
    ExpandIcon,
    Root: styled.div<{ $expanded: boolean }>`
      display: flex;
      flex-flow: column;
      padding: 12px 18px;
      padding-top: 0;
      border-radius: 5px;
      color: white;
      background-color: ${vars.colors.brand};

      ${(props) =>
        props.$expanded &&
        css`
          ${Counter} {
            display: none;
          }

          ${ExpandIcon} {
            transform: rotate(180deg);
          }
        `}
    `,
    Header: styled.button.attrs({ type: 'button' })`
      ${ButtonCss};
      padding-top: 12px;
      color: inherit;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
    `,
    HeaderRight: styled.div`
      display: flex;
      align-items: center;
      gap: 10px;
    `,
    Body: styled.div`
      padding-top: 20px;
      font-size: 14px;
    `,
    ResetButtonWrapper: styled.div`
      display: flex;
      justify-content: flex-end;
    `,
    ResetButton: styled.button.attrs({ type: 'button' })`
      ${ButtonCss};
      margin-top: 20px;
      color: inherit;
      display: flex;
      font-weight: 600;
    `,
  };
})();

const Options = styled.div<{ $renderInline: boolean }>`
  display: grid;
  grid-row-gap: 14px;
  ${(props) =>
    props.$renderInline
      ? css`
          grid-template-columns: 1fr 1fr;
        `
      : css`
          grid-template-columns: 1fr;
        `}
`;

const Option = styled.button.attrs({ type: 'button' })`
  ${ButtonCss};
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  color: inherit;
  width: calc(50% - var(--options-gap) / 2);
`;

const OptionIndicator = styled.div<{ $selected: boolean }>`
  border-radius: 5px;
  margin-right: 6px;
  width: 21px;
  height: 21px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  ${(props) =>
    props.$selected &&
    css`
      border-color: white;
      background-color: white;
    `}
`;

const Categories = styled.div`
  display: flex;
  flex-flow: column;
  gap: 6px;
`;

const SearchIcon = styled.svg.attrs({ fill: 'currentColor' })`
  &,
  &:after {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 14px;
    pointer-events: none;
  }

  padding: 1px;
`;

const SearchInputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input.attrs({ type: 'search' })`
  width: 100%;
  height: 40px;
  padding: 0 14px;
  font-size: 1rem;
  text-indent: 24px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
  outline: none;
  color: white;
  appearance: none;

  @media (hover: hover) {
    font-size: inherit;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  &::-webkit-search-cancel-button {
    appearance: none;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/clear-search-category.svg');
    cursor: pointer;
    border-radius: 50%;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

    @media (hover: hover) {
      ${vars.breakpoint.abovePhone} {
        &:hover {
          background-color: #817eff;
        }
      }
    }
  }
`;

const Category = styled.button.attrs({ type: 'button' })<{ $selected: boolean }>`
  ${ButtonCss};
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 20px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: inherit;
  text-align: left;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  ${(props) =>
    props.$selected &&
    css`
      background-color: #817eff;
    `}
`;
