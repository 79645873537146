import { css } from 'styled-components';
import vars from '../vars';

const medium = 1650;

export const ContentWidthCss = css`
  width: 800px;

  @media (max-width: ${medium}px) {
    width: 600px;
  }

  ${vars.breakpoint.phone} {
    width: 100%;
  }
`;

export const ContentFontSizeCss = css`
  font-size: 20px;

  @media (max-width: ${medium}px) {
    font-size: 18px;
  }

  ${vars.breakpoint.phone} {
    font-size: 16px;
  }
`;
